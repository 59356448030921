.img-account-profile {
  height: 10rem;
}

.rounded-circle {
  border-radius: 50% !important;
}

.card {
  box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%);

  .card-header {
    font-weight: 500;
  }
}

.card-header {
  &:first-child {
    border-radius: 0.35rem 0.35rem 0 0;
  }

  padding: 1rem 1.35rem;
  margin-bottom: 0;
  background-color: rgba(33, 40, 50, 0.03);
  border-bottom: 1px solid rgba(33, 40, 50, 0.125);
}

.form-control,
.dataTable-input {
  /* display: block;
  width: 100%;
  padding: 0.875rem 1.125rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: #69707a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c5ccd6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;*/
}

.nav-borders .nav-link {
  &.active {
    color: #0061f2;
    border-bottom-color: #0061f2;
  }

  color: #69707a;
  border-bottom-width: 0.125rem;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  margin-left: 1rem;
  margin-right: 1rem;
}

// Secure

.btn-danger-soft {
  color: #000;
  background-color: #f1e0e3;
  border-color: #f1e0e3;
}

// Billing

.fa-2x {
  font-size: 2em;
}

.table-billing-history {
  th,
  td {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }
}

.table > :not(caption) > * > *,
.dataTable-table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.border-start-primary {
  border-left-color: #0061f2 !important;
}

.border-start-secondary {
  border-left-color: #6900c7 !important;
}

.border-start-success {
  border-left-color: #00ac69 !important;
}

.border-start-lg {
  border-left-width: 0.25rem !important;
}

.h-100 {
  height: 100% !important;
}
